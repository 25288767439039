import React from 'react';
import { UpgradeAccountBanner } from '@common/components/AccountUpgrade';
import { AppDownloadReminderBanner } from '@common/components/AppDownloadReminderBanner';
import {
  BackToJobsLink,
  WhatHappensNextInfo
} from '@common/components/Caja/components';
import { useFreeAndUpgradableAccount } from '@common/components/Caja/hooks';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { TopLevelStep } from '@common/hooks/multiStep';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { useTranslation } from '@common/hooks/useTranslation';
import { useCurrentUser } from '@common/hooks/user';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { PAYMENT_CAMPAIGN } from '@store/payment/constants';
import { CajaVariant } from '../../variants';
import { HeaderInfo } from './HeaderInfo';
import { UpgradeJobContentRouter } from './UpgradeJobContentRouter';

type StepInfo = {
  cajaVariant: CajaVariant;
  jobUpgradeInfo: {
    isJobUpgradable: boolean;
    jobCountryCode: SupportedCountryCode;
    jobId: string;
  };
};

type Props = Pick<TopLevelStep<StepInfo>, 'stepInfo'>;

export const PostJobSuccess = ({
  stepInfo: {
    cajaVariant,
    jobUpgradeInfo: { isJobUpgradable, jobCountryCode, jobId }
  }
}: Props) => {
  useRedirectUnauthenticatedUser();

  const { t } = useTranslation();
  const { email, isVerified } = useCurrentUser();
  const freeAndUpgradeableAccount = useFreeAndUpgradableAccount();
  const isFirstTimePoster = isVerified === undefined;
  const accountUpgradeSource = 'post_job_success_caja_step';

  const UpgradeJobContent = UpgradeJobContentRouter({ cajaVariant });

  return (
    <div className="flex flex-col gap-md mb-md">
      <JoraEmployerHelmet title={t('postJobSuccessPage.title')} />
      <HeaderInfo isFirstTimePoster={isFirstTimePoster} />
      <WhatHappensNextInfo
        emailAddress={email!}
        showUpgradeAccountInfo={freeAndUpgradeableAccount}
        stepTitle={t('postJobSuccessPage.title')}
        source={`${accountUpgradeSource}_what_happens_next_info`}
      />
      {isJobUpgradable && (
        <UpgradeJobContent
          jobCountryCode={jobCountryCode}
          jobId={jobId}
          campaignId={PAYMENT_CAMPAIGN.POST_JOB_SUCCESS}
        />
      )}
      {freeAndUpgradeableAccount && (
        <UpgradeAccountBanner
          pageTitle={t('postJobSuccessPage.title')}
          source={`${accountUpgradeSource}_banner`}
        />
      )}

      <AppDownloadReminderBanner testID="app-download-banner" />

      <BackToJobsLink />
    </div>
  );
};
