export const plusAdBenefits = [
  {
    title: 'upgradeJob.plusPackage.benefits1.title',
    points: ['upgradeJob.plusPackage.benefits1.point1']
  },
  {
    title: 'upgradeJob.plusPackage.benefits2.title',
    points: [
      'upgradeJob.plusPackage.benefits2.point1',
      'upgradeJob.plusPackage.benefits2.point2'
    ]
  },
  {
    title: 'upgradeJob.plusPackage.benefits3.title',
    points: ['upgradeJob.plusPackage.benefits3.point1']
  }
];
