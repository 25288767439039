import React from 'react';
import { UpgradeAccountBanner } from '@common/components/AccountUpgrade';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { useTranslation } from '@common/hooks/useTranslation';
import { useCurrentUser } from '@common/hooks/user';
import { BackToJobsLink, WhatHappensNextInfo } from '../../components';
import { useFreeAndUpgradableAccount } from '../../hooks';
import { HeaderInfo } from './HeaderInfo';

export const PaymentSuccess = () => {
  useRedirectUnauthenticatedUser();

  const { t } = useTranslation();
  const { email } = useCurrentUser();
  const freeAndUpgradeableAccount = useFreeAndUpgradableAccount();
  const accountUpgradeSource = 'payment_success_caja_step';

  return (
    <div className="flex flex-col gap-md mb-md">
      <JoraEmployerHelmet title={t('paymentSuccessPage.title')} />
      <HeaderInfo />
      <WhatHappensNextInfo
        emailAddress={email!}
        showUpgradeAccountInfo={freeAndUpgradeableAccount}
        stepTitle={t('paymentSuccessPage.title')}
        source={`${accountUpgradeSource}_what_happens_next_info`}
      />
      {freeAndUpgradeableAccount && (
        <UpgradeAccountBanner
          pageTitle={t('paymentSuccessPage.title')}
          source={`${accountUpgradeSource}_banner`}
        />
      )}
      <BackToJobsLink />
    </div>
  );
};
