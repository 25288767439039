import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Badge } from '@components/Badge';

type Props = {
  text: string;
  description?: string;
  disabled?: boolean;
};

export const FieldLabel = ({ text, description, disabled = false }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-row gap-sm mb-xsm">
        <label
          className={clsx(
            'text-md font-medium cursor-pointer leading-md',
            disabled && 'opacity-60'
          )}
        >
          {text}
          <span className="mx-3 inline-block">
            <Badge tone="promo">{t('adBadge.plusOrMax')}</Badge>
          </span>
        </label>
      </div>
      {description && (
        <div className="text-sm font-light mt-3">{description}</div>
      )}
    </>
  );
};
