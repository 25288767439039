import React from 'react';
import { RequestErrorMessage } from '@common/components/RequestErrorMessage';
import { useGetAdDuration } from '@common/hooks/job';
import { useLoadAdPrices } from '@common/hooks/payment';
import { useTranslation } from '@common/hooks/useTranslation';
import { AD_TYPE } from '@seek/je-shared-data';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { getImmediateStartName } from '../../Job/EnhanceJob/components/getImmediateStartName';
import { BaseUpgradeSelector } from '../BaseUpgradeSelector';
import { PremiumUpgradeSelector } from '../PremiumUpgradeSelector';
import { CurrencyAmount } from '../PriceInformation';

type Props = {
  jobCountryCode: SupportedCountryCode;
  onChange: (adType: AD_TYPE) => void;
  value: AD_TYPE;
  disabled?: boolean;
  showBasicAdType?: boolean;
};

/**
 * Component to display currency payment options for job ads
 */
export const CurrencyUpgradeSelector = (props: Props) => {
  const {
    jobCountryCode,
    onChange,
    value,
    disabled = false,
    showBasicAdType = true
  } = props;
  const { data: adPrice, errorKey } = useLoadAdPrices(jobCountryCode);
  const { adDuration } = useGetAdDuration(jobCountryCode);
  const { t } = useTranslation();

  const immediateStartText = t(getImmediateStartName(jobCountryCode));

  if (showBasicAdType) {
    return (
      <div className="flex flex-col gap-lg">
        <div className="flex flex-col gap-md" data-testid="currency-ad-panel">
          <BaseUpgradeSelector
            adDuration={adDuration}
            price={{
              [AD_TYPE.PLUS]: <CurrencyAmount price={adPrice[AD_TYPE.PLUS]} />,
              [AD_TYPE.MAX]: <CurrencyAmount price={adPrice[AD_TYPE.MAX]} />
            }}
            value={value}
            onChange={onChange}
            disabled={disabled}
            immediateStartText={immediateStartText}
          />
        </div>

        <RequestErrorMessage requestKey={errorKey} />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-lg">
      <div className="flex flex-col gap-md" data-testid="currency-ad-panel">
        <PremiumUpgradeSelector
          adDuration={adDuration}
          price={{
            [AD_TYPE.PLUS]: <CurrencyAmount price={adPrice[AD_TYPE.PLUS]} />,
            [AD_TYPE.MAX]: <CurrencyAmount price={adPrice[AD_TYPE.MAX]} />
          }}
          value={value}
          onChange={onChange}
          disabled={disabled}
          immediateStartText={immediateStartText}
        />
      </div>

      <RequestErrorMessage requestKey={errorKey} />
    </div>
  );
};
