import React from 'react';
import { Trans } from 'react-i18next';
import { CalloutText } from '@components/CalloutText';
import { Link } from '@components/Link';
import { Text } from '@components/Text';

type Props = {
  onUpgradeClicked: () => void;
};

export const BusinessInformationUpgradeAccountCallout = (props: Props) => {
  const { onUpgradeClicked } = props;

  return (
    <CalloutText tone="info">
      <Text>
        <Trans
          i18nKey={'accountUpgrade.callout.manageMultipleBusinesses'}
          components={[<Link onPress={onUpgradeClicked} />]}
        />
      </Text>
    </CalloutText>
  );
};
