import React from 'react';
import { useBrandNameText } from '@common/hooks/localisation';
import { useRequestUpgradeAccountCallback } from '@common/hooks/orgs/useRequestUpgradeAccountCallback';
import { useTrackContactMeButtonClicked } from '@src/joraEmployer/pages/account/hooks/useTrackContactMeClicked/useTrackContactMeClicked';
import { getUser } from '@store/auth';
import { useAppSelector } from '@store/hooks';
import { BaseUpgradeAccountModal } from '../BaseUpgradeAccountModal';

type Props = {
  modalOptions: {
    isModalOpen: boolean;
    openModal: () => void;
    closeModal: () => void;
  };
  trackingProperties: {
    pageSource: string;
    componentSource: string;
  };
  onUpgradeRequestConfirmed: () => void;
  upgradeRequestConfirmedText?: string;
};

export const UpgradeAccountModal = ({
  trackingProperties,
  onUpgradeRequestConfirmed,
  upgradeRequestConfirmedText,
  modalOptions: { isModalOpen, closeModal }
}: Props) => {
  const { currentUser } = useAppSelector(getUser);

  const trackContactMeClicked = useTrackContactMeButtonClicked();
  const { requestUpgradeAccountCallback } = useRequestUpgradeAccountCallback();
  const brandNameText = useBrandNameText();

  const handleRequestUpgradeSubmit = () => {
    requestUpgradeAccountCallback({
      page: `${trackingProperties.pageSource} | ${brandNameText}`,
      source: trackingProperties.componentSource
    });
    trackContactMeClicked();
  };

  return (
    <BaseUpgradeAccountModal
      mobile={currentUser.mobile}
      email={currentUser.email}
      visible={isModalOpen}
      onRequestClose={closeModal}
      onUpgradeRequested={handleRequestUpgradeSubmit}
      onUpgradeRequestConfirmed={onUpgradeRequestConfirmed}
      upgradeRequestConfirmedText={upgradeRequestConfirmedText}
    />
  );
};
