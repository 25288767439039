import React, { useEffect } from 'react';
import { CajaWrapper, StepLoader } from '@common/components/Caja/components';
import {
  useAdTypeState,
  useBrowserBackNavigationListener,
  useCajaVariant,
  useGetPaymentIntentParam,
  useTrackCajaEvent
} from '@common/components/Caja/hooks';
import { useGetCompletedUrl } from '@common/components/Caja/steps/PaymentJobStep/hooks/useGetCompletedUrl';
import {
  CreateAccount,
  CreateBusiness,
  JobSummaryAndDesc,
  MultiStepJobEdit,
  MultiStepJobPost,
  PostJobSuccess,
  VerifyEmail,
  enhanceJobRouter,
  getImmediateStartPhrase,
  getRightToWorkDocument,
  isEditJobStep,
  isPostJobStep,
  paymentJobRouter
} from '@common/componentsV2/Caja/steps';
import { useBusinessState } from '@common/hooks/business';
import { useFetchJobData } from '@common/hooks/job';
import { useSteps } from '@common/hooks/multiStep';
import { useUpgradePermissions } from '@common/hooks/payment';
import { useUpdateSourceQuery } from '@common/hooks/useUpdateSourceQuery';
import { SupportedCountryCode as CountryCode } from '@seek/je-shared-data/lib/types/brand';
import { UpgradeableAdType, upgraded } from '@src/job';
import { toLowerCase } from '@src/lib/formatting';
import { useGetJobIdParam } from './hooks';
import {
  GetStepsParams,
  SignUpStepIds,
  getPageTitle,
  getStepNumber,
  getStepTitle,
  getSteps,
  ignoreIndicator,
  totalNumberOfSteps
} from './stepsInfo';

export function SignUp() {
  useUpdateSourceQuery();

  const urlJobId = useGetJobIdParam();
  const { hasPaymentIntent } = useGetPaymentIntentParam(); // for handling payment race condition; see https://jobseeker.atlassian.net/browse/AD-753?focusedCommentId=57731
  const cajaVariant = useCajaVariant();
  const { getOnCompletedUrl } = useGetCompletedUrl();
  const { isUpgradeable } = useUpgradePermissions();
  const [selectedAdType, setSelectedAdType] = useAdTypeState();

  const {
    data: jobData,
    isLoading: fetchJobLoading,
    refetch
  } = useFetchJobData({
    jobId: urlJobId
  });

  const [createdJobCountryCode, setCountryCode] = useBusinessState(
    toLowerCase<CountryCode>(jobData?.countryCode)
  );
  const isJobUpgradable = isUpgradeable(createdJobCountryCode);

  const { steps, currentStep, nextStep, gotoNextStep, gotoPreviousStep } =
    useSteps<GetStepsParams, SignUpStepIds>({
      data: {
        createdJobId: urlJobId,
        isUpgradeable: isJobUpgradable,
        selectedAdType,
        hasJobUpgraded: upgraded(jobData?.adType) || hasPaymentIntent
      },
      getSteps
    });

  useEffect(() => {
    if (urlJobId) refetch();
  }, [currentStep.id, urlJobId, refetch]);

  useBrowserBackNavigationListener(gotoPreviousStep);

  useTrackCajaEvent({
    step: currentStep.id,
    variant: `sign-up-${cajaVariant}`,
    jobId: urlJobId
  });

  const EnhanceJob = enhanceJobRouter({ cajaVariant });
  const PaymentJob = paymentJobRouter({ cajaVariant });

  if (fetchJobLoading) return <StepLoader />;

  return (
    <CajaWrapper
      title={getPageTitle(currentStep.id)}
      ignoreIndicator={ignoreIndicator(currentStep.id)}
      step={{
        number: getStepNumber(steps, currentStep.path),
        title: getStepTitle(currentStep.id),
        total: totalNumberOfSteps(steps)
      }}
    >
      {currentStep.id === 'createAccount' && (
        <CreateAccount onCompleted={gotoNextStep} />
      )}
      {currentStep.id === 'verifyEmail' && (
        <VerifyEmail onCompleted={gotoNextStep} />
      )}
      {currentStep.id === 'createBusiness' && (
        <CreateBusiness onCompleted={gotoNextStep} />
      )}
      {isPostJobStep(currentStep.id) && (
        <MultiStepJobPost
          stepInfo={{
            currentStepId: currentStep.id
          }}
          onCompleted={({ jobId: id, jobCountryCode }) => {
            setCountryCode(jobCountryCode);
            gotoNextStep({
              createdJobId: id,
              isUpgradeable: isUpgradeable(
                toLowerCase<CountryCode>(jobCountryCode)
              )
            });
          }}
          onNext={(data) => {
            if (data.jobCountryCode) setCountryCode(data.jobCountryCode);
            gotoNextStep(data);
          }}
          onBack={gotoPreviousStep}
        />
      )}
      {isEditJobStep(currentStep.id) && (
        <MultiStepJobEdit
          onCompleted={gotoNextStep}
          onNext={gotoNextStep}
          onBack={gotoPreviousStep}
          stepInfo={{
            jobId: urlJobId!,
            job: jobData!!,
            currentStepId: currentStep.id
          }}
        />
      )}
      {currentStep.id === 'enhanceJob' && (
        <EnhanceJob
          stepInfo={{
            jobId: urlJobId!,
            jobCountryCode: createdJobCountryCode!,
            disableAdTypeSelector: upgraded(jobData?.adType),
            initialAdType: upgraded(jobData?.adType)
              ? jobData?.adType
              : selectedAdType
          }}
          onCompleted={({ jobAdType, hasJobUpgraded }) => {
            setSelectedAdType(jobAdType);
            gotoNextStep({
              createdJobId: urlJobId,
              selectedAdType: jobAdType,
              hasJobUpgraded: hasJobUpgraded || upgraded(jobData?.adType)
            });
          }}
          onBack={gotoPreviousStep}
        />
      )}
      {currentStep.id === 'payment' && (
        <PaymentJob
          stepInfo={{
            jobId: urlJobId!,
            jobCountryCode: createdJobCountryCode!,
            adType: selectedAdType as UpgradeableAdType,
            onCompletedUrl: getOnCompletedUrl(nextStep)
          }}
          onBack={gotoPreviousStep}
        />
      )}
      {currentStep.id === 'jobSummaryAndDesc' && (
        <JobSummaryAndDesc
          stepInfo={{
            jobId: urlJobId!,
            showAdTypeBadge: isJobUpgradable,
            adType: selectedAdType,
            rightToWorkDocument: getRightToWorkDocument(cajaVariant),
            immediateStartPhrase: getImmediateStartPhrase(createdJobCountryCode)
          }}
          onCompleted={gotoNextStep}
          onBack={gotoPreviousStep}
        />
      )}
      {currentStep.id === 'postJobSuccess' && (
        <PostJobSuccess
          stepInfo={{
            cajaVariant,
            jobUpgradeInfo: {
              isJobUpgradable,
              jobCountryCode: createdJobCountryCode!,
              jobId: urlJobId!
            }
          }}
        />
      )}
    </CajaWrapper>
  );
}
