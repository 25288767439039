import React from 'react';
import { Trans } from 'react-i18next';
import { CalloutText } from '@components/CalloutText';
import { Link } from '@components/Link';
import { Text } from '@components/Text';

type Props = {
  onUpgradeClicked: () => void;
  onRedirectToMyBusinessesClicked: () => void;
};

export const BusinessSelectorUpgradeAccountCallout = (props: Props) => {
  const { onUpgradeClicked, onRedirectToMyBusinessesClicked } = props;

  return (
    <CalloutText tone="info">
      <Text>
        <Trans
          i18nKey={
            'accountUpgrade.callout.manageMultipleBusinessAndMakeUpdates'
          }
          components={[
            <Link onPress={onUpgradeClicked} />,
            <Link onPress={onRedirectToMyBusinessesClicked} />
          ]}
        />
      </Text>
    </CalloutText>
  );
};
