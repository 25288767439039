import React from 'react';
import { useTranslation } from '@common/hooks/useTranslation';
import { Heading } from '@components/Heading';
import { BudgetFriendlyIllustration } from '@components/Illustration';
import { Text } from '@components/Text';

export const HeaderInfo = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col pt-[24px] p-lg items-center gap-md bg-background-neutral">
      <BudgetFriendlyIllustration size="small" />
      <div className="flex flex-col gap-sm">
        <Heading level="h1" weight="regular" align="center">
          {t('paymentSuccessPage.heading')}
        </Heading>
        <Text align="center">{t('paymentSuccessPage.subHeading')}</Text>
      </div>
    </div>
  );
};
