import React from 'react';
import { I18nextTrans, useTranslation } from '@common/hooks/useTranslation';
import { Text } from '@components/Text';
import { Benefit } from './Benefit';
import { maxAdBenefits } from './maxAdBenefits';

export type Props = {
  adDuration?: number;
  immediateStartText: string;
  price: React.ReactNode;
};

export const MaxAdInfo = (props: Props) => {
  const { adDuration = '-', price, immediateStartText } = props;
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      <div className="flex flex-col">
        <div className="flex flex-row justify-between">
          <Text size="xlarge" weight="regular" testID="max-package">
            {t('upgradeJob.maxPackage.name')}
          </Text>
          {price}
        </div>
        <Text size="small" weight="regular" tone="neutralLight">
          {t('upgradeJob.maxPackage.details', { adDuration })}
        </Text>
      </div>

      <div className="flex flex-col gap-sm mt-md">
        {maxAdBenefits.map((benefit, index) => (
          <div key={index} className="flex flex-col gap-1">
            <Text weight="regular" size="standard">
              <I18nextTrans
                i18nKey={benefit.title}
                components={[<Text size="standard" weight="bold" />]}
              />
            </Text>
            <div className="flex flex-col">
              {benefit.points.map((point, pointIndex) => (
                <Benefit key={pointIndex}>
                  {t(point, { immediateStartName: immediateStartText })}
                </Benefit>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
