import { useFreeAndUpgradableAccount } from '@common/components/Caja/hooks';
import { useActiveBusinesses } from '@common/hooks/business';
import { filterInvalidSearchParams } from '@common/search';
import {
  REDIRECT_TO,
  RedirectTo,
  SearchParamProps
} from '@common/search/filterInvalidSearchParams';
import { getBusinesses } from '@store/entities/businesses/selectors';
import { BusinessWithUsers } from '@store/entities/businesses/types';
import { getJobs } from '@store/entities/jobs';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';

export type UseChangeBusinessModalProps = {
  onRequestClose: () => void;
  shouldRedirectBusinessCreatedTo: RedirectTo;
  onBusinessSelected: (business: BusinessWithUsers) => void;
};

const useGetNewBusinessUrl = ({
  shouldRedirectBusinessCreatedTo: shouldRedirectTo
}: {
  shouldRedirectBusinessCreatedTo: RedirectTo;
}) => {
  const { countryCode } = useAppSelector(getLocalisation);
  const jobs = useAppSelector(getJobs());

  const jobId = Object.keys(jobs)[0];
  const isFromRepostJob = shouldRedirectTo === REDIRECT_TO.repostJob;

  const searchParams: SearchParamProps = {
    shouldRedirectTo,
    ...(isFromRepostJob ? { jobId } : {})
  };

  const queryParams = new URLSearchParams(
    filterInvalidSearchParams(searchParams)
  );

  return `/${countryCode}/businesses/create?${queryParams}`;
};

const useHandleBusinessSelected = ({
  onBusinessSelected,
  onRequestClose
}: Pick<
  UseChangeBusinessModalProps,
  'onBusinessSelected' | 'onRequestClose'
>) => {
  const businesses = useAppSelector(getBusinesses());

  const handleBusinessSelected = (businessId: string) => {
    const selectedBusiness = businesses.find(({ id }) => id === businessId);

    if (selectedBusiness) {
      onBusinessSelected(selectedBusiness);
    }

    onRequestClose();
  };

  return handleBusinessSelected;
};

export const useChangeBusinessModal = ({
  shouldRedirectBusinessCreatedTo,
  onBusinessSelected,
  onRequestClose
}: UseChangeBusinessModalProps) => {
  const businesses = useActiveBusinesses();
  const addNewBusinessUrl = useGetNewBusinessUrl({
    shouldRedirectBusinessCreatedTo
  });
  const handleBusinessSelected = useHandleBusinessSelected({
    onBusinessSelected,
    onRequestClose
  });
  const accountUpgradeable = useFreeAndUpgradableAccount();

  return {
    accountUpgradeable,
    addNewBusinessUrl,
    businesses,
    handleBusinessSelected
  };
};
