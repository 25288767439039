import { CAJA_VARIANTS, CajaVariant } from '@common/components/Caja/variants';
import { UpgradeJobContent as UpgradeJobContentWithCoin } from './default_jse';
import { UpgradeJobContent as UpgradeJobContentWithCurrency } from './default_v2';

type Params = {
  cajaVariant: CajaVariant;
};

export const UpgradeJobContentRouter = (param: Params) => {
  if (param.cajaVariant === CAJA_VARIANTS.DEFAULT) {
    return UpgradeJobContentWithCurrency;
  }

  return UpgradeJobContentWithCoin;
};
