import React from 'react';
import { UpgradeAccountModal } from '@common/components/AccountUpgrade';
import { useFreeAndUpgradableAccount } from '@common/components/Caja/hooks';
import { ErrorMessage } from '@common/components/ErrorMessage';
import REQUEST_KEY from '@common/constants/requestKeys';
import { useTranslation } from '@common/hooks/useTranslation';
import { useAtBusinessLimit } from '@common/hooks/userPackages';
import { RedirectTo } from '@common/search';
import { useTrackUpgradeAccountButtonClicked } from '@src/joraEmployer/pages/account/hooks';
import { getRequestState } from '@store/app';
import { BusinessWithUsers } from '@store/entities/businesses/types';
import { useAppSelector } from '@store/hooks';
import { useModal } from '@components/Modal';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { DisplayBusinessInformation } from './DisplayBusinessInformation';
import { EditableBusinessInformation } from './EditableBusinessInformation';
import { BusinessInformationUpgradeAccountCallout } from './components/AccountUpgradeCallouts';
import { CreateBusinessLink } from './components/CreateBusinessLink';
import { LoadBusinessErrorMessage } from './components/LoadBusinessErrorMessage';
import { PostJobFormBusiness } from './types';

type BusinessInformationProps = {
  isBusinessEditable: boolean;
  shouldRedirectBusinessCreatedTo: RedirectTo | undefined;
  handleBusinessSelected: (business: BusinessWithUsers) => void;
  business?: PostJobFormBusiness;
  error?: string;
};

export const BusinessInformation = ({
  business,
  isBusinessEditable,
  shouldRedirectBusinessCreatedTo,
  handleBusinessSelected,
  error
}: BusinessInformationProps) => {
  const { t } = useTranslation();
  const { error: loadBusinessesError } = useAppSelector(
    getRequestState(REQUEST_KEY.BUSINESS.LIST)
  );
  const atBusinessLimit = useAtBusinessLimit();
  const accountUpgradeable = useFreeAndUpgradableAccount();
  const { isModalOpen, openModal, closeModal } = useModal();
  const componentSource = 'business_information';
  const trackUpgradeAccountButtonClicked = useTrackUpgradeAccountButtonClicked({
    source: componentSource
  });

  const onUpgradeClicked = () => {
    openModal();
    trackUpgradeAccountButtonClicked();
  };

  const renderBusinessInformation = (selectedBusiness: PostJobFormBusiness) =>
    isBusinessEditable && shouldRedirectBusinessCreatedTo ? (
      <EditableBusinessInformation
        selectedBusiness={selectedBusiness}
        shouldRedirectBusinessCreatedTo={shouldRedirectBusinessCreatedTo}
        onBusinessSelected={handleBusinessSelected}
        disableAddBusiness={atBusinessLimit}
      />
    ) : (
      <DisplayBusinessInformation selectedBusiness={selectedBusiness} />
    );

  return (
    <Stack>
      <Stack spacing="medium">
        <Text>
          {isBusinessEditable
            ? t('postJobForm.businessInformation.businessWillBeLinkedToJob')
            : t('postJobForm.businessInformation.businessAlreadyLinkedToJob')}
        </Text>

        {loadBusinessesError && <LoadBusinessErrorMessage />}
        {!loadBusinessesError && !business && <CreateBusinessLink />}
        {business && renderBusinessInformation(business)}
        {accountUpgradeable && (
          <BusinessInformationUpgradeAccountCallout
            onUpgradeClicked={onUpgradeClicked}
          />
        )}

        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Stack>
      <UpgradeAccountModal
        onUpgradeRequestConfirmed={closeModal}
        upgradeRequestConfirmedText={t('common.action.back')}
        modalOptions={{
          isModalOpen,
          openModal,
          closeModal
        }}
        trackingProperties={{
          pageSource: '',
          componentSource
        }}
      />
    </Stack>
  );
};
