export const GET_AD_PRICES = 'jora-employer/entities/payment/GET_AD_PRICES';
export const GET_AD_PRICES_SUCCESS =
  'jora-employer/entities/payment/GET_AD_PRICES_SUCCESS';
export const GET_AD_PRICES_FAIL =
  'jora-employer/entities/payment/GET_AD_PRICES_FAIL';

export const CREATE_PAYMENT_INTENT =
  'jora-employer/entities/payment/CREATE_PAYMENT_INTENT';
export const CREATE_PAYMENT_INTENT_SUCCESS =
  'jora-employer/entities/payment/CREATE_PAYMENT_INTENT_SUCCESS';
export const CREATE_PAYMENT_INTENT_FAIL =
  'jora-employer/entities/payment/CREATE_PAYMENT_INTENT_FAIL';

export const CONFIRM_PAYMENT_INTENT =
  'jora-employer/entities/payment/CONFIRM_PAYMENT_INTENT';
export const CONFIRM_PAYMENT_INTENT_SUCCESS =
  'jora-employer/entities/payment/CONFIRM_PAYMENT_INTENT_SUCCESS';
export const CONFIRM_PAYMENT_INTENT_FAIL =
  'jora-employer/entities/payment/CONFIRM_PAYMENT_INTENT_FAIL';

export const CONFIRM_PAYMENT = 'jora-employer/entities/payment/CONFIRM_PAYMENT';
export const CONFIRM_PAYMENT_SUCCESS =
  'jora-employer/entities/payment/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_FAIL =
  'jora-employer/entities/payment/CONFIRM_PAYMENT_FAIL';
export const CONFIRM_PAYMENT_FORM = 'jora-employer-checkout-form';

export const LOAD_STRIPE_INSTANCE =
  'jora-employer/entities/payment/LOAD_STRIPE_INSTANCE';

export const TRACK_AD_TYPE_BUTTON_CLICK =
  'jora-employer/entities/payment/TRACK_AD_TYPE_BUTTON_CLICK';

export const TRACK_CONFIRM_PAYMENT_SUCCESS =
  'jora-employer/entities/payment/TRACK_CONFIRM_PAYMENT_SUCCESS';

export const PAYMENT_CAMPAIGN = {
  CANDIDATE_MANAGEMENT: 'candidate management v4',
  DEFAULT: 'post job success v10',
  EMAIL_LINK: 'email link v5',
  JOBS_DASHBOARD: 'jobs dashboard v1',
  MULTI_STEP_CAJA: 'multi step caja default v2',
  POST_JOB_SUCCESS: 'post job success v1'
} as const;

export type PaymentCampaignType =
  (typeof PAYMENT_CAMPAIGN)[keyof typeof PAYMENT_CAMPAIGN];

export const PAYMENT_METHOD = {
  card: 'card',
  indonesianBankTransfer: 'id_bank_transfer'
} as const;

export type PaymentMethodType =
  (typeof PAYMENT_METHOD)[keyof typeof PAYMENT_METHOD];
