import React from 'react';
import { useAccountUpgradeable } from '@common/hooks/featureToggles/useAccountUpgradeable';
import { useGetUserPackage } from '@common/hooks/userPackages';
import { Heading } from '@components/Heading';
import { Stack } from '@components/Stack';
import { UserPackage } from '../AccountUpgrade/UserPackage';

type PageHeadingWithUserPackageProps = {
  heading: string;
};

export const PageHeadingWithUserPackage = ({
  heading
}: PageHeadingWithUserPackageProps) => {
  const accountUpgradeable = useAccountUpgradeable();
  const { packageType, expiryDate } = useGetUserPackage();

  return (
    <Stack
      tone="transparent"
      spacing={{ desktop: 'small', tablet: 'small', mobile: 'xsmall' }}
      direction={{ desktop: 'row', tablet: 'row', mobile: 'column' }}
    >
      <Heading level="h1" weight="regular">
        {heading}
      </Heading>

      {accountUpgradeable && (
        <UserPackage packageType={packageType} expiryDate={expiryDate} />
      )}
    </Stack>
  );
};
