import React, { forwardRef, useImperativeHandle } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { getImmediateStartName } from '@common/components/Job/EnhanceJob/components/getImmediateStartName';
import {
  ImmediateStart,
  RightToWork,
  VideoScreeningQuestions
} from '@common/components/Job/formFields';
import { caja2Options } from '@common/components/Job/formFields/fields/RightToWork/options';
import { LOCALISED_COUNTRIES } from '@common/constants/data';
import { useTranslation } from '@common/hooks/useTranslation';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { objectHasValues } from '@src/lib/func/objectHasValues';
import { NavControls } from '../../components';
import { FieldLabel } from '../components';
import { ImmediateStartIllustration } from '../components/ImmediateStartIllustration';

export type EnhanceFormData = {
  rightToWork: boolean;
  immediateStart: boolean;
  videoScreeningQuestion: string;
};

export type EnhanceFormRef = {
  clearErrors: UseFormReturn<EnhanceFormData>['clearErrors'];
  resetFormValues: UseFormReturn<EnhanceFormData>['reset'];
};

type Props = {
  disabled: boolean;
  onBack: () => void;
  onNext: (data?: EnhanceFormData) => void;
  onSubmit: (data?: EnhanceFormData) => void;
  defaultValues?: Partial<EnhanceFormData>;
  jobCountryCode: SupportedCountryCode;
  onNextText?: string;
  hideBackButton?: boolean;
};

export const EnhanceJobForm = forwardRef<EnhanceFormRef, Props>(
  (props: Props, ref) => {
    const {
      onBack,
      onNext,
      onSubmit,
      defaultValues,
      disabled,
      jobCountryCode,
      onNextText,
      hideBackButton
    } = props;
    const { t } = useTranslation();
    const { handleSubmit, control, formState, clearErrors, reset } =
      useForm<EnhanceFormData>({
        defaultValues,
        values: objectHasValues(defaultValues) ? defaultValues : undefined,
        disabled
      });

    useImperativeHandle(ref, () => ({
      clearErrors,
      resetFormValues: reset
    }));

    const onFormSubmit = async (data: EnhanceFormData) => {
      await onSubmit(data);
      onNext(data);
    };

    const onFormError = () => {
      if (disabled) {
        clearErrors();
        onNext();
      }
    };

    const isFormInvalid =
      formState.isSubmitted && !formState.isValid && !disabled;

    const immediateStartText = t(getImmediateStartName(jobCountryCode));

    return (
      <form onSubmit={handleSubmit(onFormSubmit, onFormError)}>
        <div
          className="flex flex-col gap-lg"
          data-testid="enhance-job-form-jse"
        >
          <RightToWork
            name="rightToWork"
            control={control}
            options={caja2Options(t)}
            label={
              <FieldLabel
                text={t('postJobForm.fieldLabel.rightToWorkQuestion', {
                  country: LOCALISED_COUNTRIES(t)[jobCountryCode]
                })}
              />
            }
          />
          <ImmediateStart
            name="immediateStart"
            control={control}
            options={{
              illustration: (
                <ImmediateStartIllustration jobCountryCode={jobCountryCode} />
              )
            }}
            label={
              <FieldLabel
                text={t('postJobForm.fieldLabel.immediateStart', {
                  immediateStartName: immediateStartText
                })}
                description={t(
                  'postJobForm.fieldLabel.immediateStartDescription'
                )}
              />
            }
          />
          <VideoScreeningQuestions
            name="videoScreeningQuestion"
            control={control}
            label={
              <FieldLabel
                text={t('postJobForm.fieldLabel.videoScreeningQuestions')}
              />
            }
          />

          <div className="flex flex-col gap-sm mt-sm">
            <NavControls
              onBack={onBack}
              onNextText={onNextText}
              hideBackButton={hideBackButton}
              onNext={handleSubmit(onFormSubmit, onFormError)}
              disabled={formState.isSubmitting}
            />

            {isFormInvalid && (
              <ErrorMessage>{t('validations.general.summary')}</ErrorMessage>
            )}
          </div>
        </div>
      </form>
    );
  }
);
