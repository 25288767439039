import React from 'react';
import { getImmediateStartPhrase } from '@common/components/Caja/steps';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import {
  ImmediateStartIllustration as StartIllustration,
  UrgentHiringIllustration,
  UrgentHiringIllustrationID
} from '@components/Illustration';
import { ImmediateStartPhrase } from '../../BaseJobSummary';

export const ImmediateStartIllustration = ({
  jobCountryCode
}: {
  jobCountryCode: SupportedCountryCode;
}) => {
  const phrase = getImmediateStartPhrase(jobCountryCode);

  if (phrase === ImmediateStartPhrase.IMMEDIATE_START) {
    return <StartIllustration />;
  }

  if (jobCountryCode === SupportedCountryCode.Indonesia) {
    return <UrgentHiringIllustrationID />;
  }

  return <UrgentHiringIllustration />;
};
