import React from 'react';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { PremiumUpsell } from '@common/components/upgradeJobAd';
import { CoinAmount } from '@common/components/upgradeJobAd/PriceInformation';
import { useGetAdDuration } from '@common/hooks/job';
import { useGetCoinPrice } from '@common/hooks/upgradeJobAd';
import { useTranslation } from '@common/hooks/useTranslation';
import { AD_TYPE } from '@seek/je-shared-data';
import { Heading } from '@components/Heading';
import { Props } from '../types';

export const UpgradeJobContent = ({
  jobCountryCode,
  jobId,
  campaignId
}: Props) => {
  const { t } = useTranslation();
  const { pricingText, fetchErrorMessage } = useGetCoinPrice({
    jobCountryCode
  });
  const { adDuration } = useGetAdDuration(jobCountryCode);

  if (!pricingText) return null;
  return (
    <div className="flex flex-col pt-[24px] p-lg gap-md bg-background-neutral">
      <div className="pb-[12px]">
        <Heading level="h2" weight="regular">
          {t('enhanceJobStep.contentHeading')}
        </Heading>
      </div>
      <PremiumUpsell
        adDuration={adDuration}
        countryCode={jobCountryCode}
        jobId={jobId}
        campaignId={campaignId}
        price={{
          [AD_TYPE.PLUS]: <CoinAmount price={pricingText[AD_TYPE.PLUS]} />,
          [AD_TYPE.MAX]: <CoinAmount price={pricingText[AD_TYPE.MAX]} />
        }}
      />

      <ErrorMessage>{fetchErrorMessage}</ErrorMessage>
    </div>
  );
};
