import React from 'react';
import { useTranslation } from 'react-i18next';
import { UpgradeAccountBanner } from '@common/components/AccountUpgrade';
import { useFreeAndUpgradableAccount } from '@common/components/Caja/hooks';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { PageHeadingWithUserPackage } from '@common/components/PageHeadingWithUserPackage';
import { RequestErrorMessages } from '@common/components/RequestErrorMessage';
import { useSiteCountryCode } from '@common/hooks/localisation';
import { useRedirect } from '@common/hooks/useRedirect';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { useCurrentUser, useHasImpersonatorToken } from '@common/hooks/user';
import { Box } from '@components/Box';
import { Card, CardContent } from '@components/Card';
import { Link } from '@components/Link';
import { Stack } from '@components/Stack';
import { AccountDetails } from './components';
import { useGetRequestErrors, useGetUnsubscribeUrl, useLogout } from './hooks';

export const Account = () => {
  const { t } = useTranslation();
  const { logout } = useLogout();
  const { redirect } = useRedirect();
  const countryCode = useSiteCountryCode();
  const { requestKeys } = useGetRequestErrors();
  const hasImpersonatorToken = useHasImpersonatorToken();

  const { givenName, surname, mobile, email, id: userId } = useCurrentUser();
  const getUnsubscribeUrl = useGetUnsubscribeUrl(userId || '');

  const freeAndUpgradeableAccount = useFreeAndUpgradableAccount();

  const pageTitle = t('common.action.account');

  useRedirectUnauthenticatedUser({ to: `/${countryCode}/login` });

  return (
    <Stack tone="transparent">
      <JoraEmployerHelmet title={pageTitle} />

      <Stack
        direction={{ desktop: 'row', tablet: 'row', mobile: 'column' }}
        spacing={{ desktop: 'none', tablet: 'none', mobile: 'small' }}
        paddingTop="medium"
        paddingBottom="small"
        paddingHorizontal="medium"
        tone="transparent"
        justifyContent="space-between"
        alignItems={{
          desktop: 'baseline',
          tablet: 'baseline',
          mobile: 'stretch'
        }}
      >
        <PageHeadingWithUserPackage heading={t('common.action.account')} />
      </Stack>

      <Stack spacing="medium" tone="transparent">
        {freeAndUpgradeableAccount && (
          <UpgradeAccountBanner
            pageTitle={pageTitle}
            source="account_page_banner"
          />
        )}

        <Card>
          <CardContent>
            <Stack spacing="xsmall">
              <AccountDetails
                givenName={givenName}
                surname={surname}
                email={email}
                mobile={mobile}
              />
            </Stack>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Stack spacing="xsmall">
              <Link
                onPress={redirect(`/${countryCode}/account/update-password`)}
              >
                {t('updatePasswordForm.action.updatePassword')}
              </Link>
              {!hasImpersonatorToken ? (
                <Link onPress={() => getUnsubscribeUrl()}>
                  {t('accountPage.manageMarketingPreferences')}
                </Link>
              ) : (
                <div className="text-md font-light">
                  {t('accountPage.manageMarketingPreferences')}
                </div>
              )}
              <Link
                onPress={redirect(`/${countryCode}/account/delete-account`)}
              >
                {t('deleteAccountSuccessPage.title')}
              </Link>
              <Link onPress={logout}>{t('common.action.logout')}</Link>

              <RequestErrorMessages requestKeys={requestKeys} />
            </Stack>
          </CardContent>
        </Card>

        <Box
          paddingLeft={{
            mobile: 'medium',
            tablet: 'xlarge',
            desktop: 'xlarge'
          }}
          paddingRight={{
            mobile: 'medium',
            tablet: 'xlarge',
            desktop: 'xlarge'
          }}
          tone="transparent"
        >
          <Box
            paddingLeft={{
              mobile: 'xsmall',
              tablet: 'small',
              desktop: 'small'
            }}
            paddingRight={{
              mobile: 'xsmall',
              tablet: 'small',
              desktop: 'small'
            }}
            tone="transparent"
          >
            <Link onPress={redirect(`/${countryCode}/`)}>
              {t('common.action.backToHome')}
            </Link>
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
};
