import { getImmediateStartPhrase } from '@common/components/Caja/steps';
import { ImmediateStartPhrase } from '@common/components/Job/BaseJobSummary';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';

export const getImmediateStartName = (countryCode: SupportedCountryCode) => {
  const phrase = getImmediateStartPhrase(countryCode);

  if (phrase === ImmediateStartPhrase.IMMEDIATE_START) {
    return 'upgradeJob.immediateStartName.immediateStart';
  }

  return 'upgradeJob.immediateStartName.urgentlyHiring';
};
