import { ImmediateStartPhrase } from '@common/components/Job/BaseJobSummary';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';

const isANZ = (countryCode: SupportedCountryCode) =>
  countryCode === SupportedCountryCode.Australia ||
  countryCode === SupportedCountryCode.NewZealand;

export const getImmediateStartPhrase = (
  jobCountryCode: SupportedCountryCode
): ImmediateStartPhrase =>
  isANZ(jobCountryCode)
    ? ImmediateStartPhrase.IMMEDIATE_START
    : ImmediateStartPhrase.URGENT_HIRING;
