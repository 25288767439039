import React from 'react';
import { useTranslation } from '@common/hooks/useTranslation';
import { Heading } from '@components/Heading';
import { JobSuccessIllustration } from '@components/Illustration';
import { Text } from '@components/Text';

type Props = {
  isFirstTimePoster: boolean;
};

export const HeaderInfo = ({ isFirstTimePoster }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col pt-[24px] p-lg items-center gap-md bg-background-neutral">
      <JobSuccessIllustration size="standard" />
      <div className="flex flex-col gap-sm">
        <Heading level="h1" weight="regular" align="center">
          {t('postJobSuccessPage.heading')}
        </Heading>
        {isFirstTimePoster ? (
          <>
            <Text align="center">
              {t('postJobSuccessPage.subHeading.liveInDays')}
            </Text>
            <Text align="center" size="small" tone="neutralLight">
              {t('postJobSuccessPage.subHeading.futurePostingsInMinutes')}
            </Text>
          </>
        ) : (
          <Text align="center">
            {t('postJobSuccessPage.subHeading.liveInMinutes')}
          </Text>
        )}
      </div>
    </div>
  );
};
