import React from 'react';
import { useGetHomeLink } from '@common/hooks/appPageLinks';
import { useRedirect } from '@common/hooks/useRedirect';
import { useTrackUpgradeAccountButtonClicked } from '@src/joraEmployer/pages/account/hooks';
import { Button } from '@components/Button';
import { useModal } from '@components/Modal';
import { UpgradeAccountModal } from '../UpgradeAccountModal/UpgradeAccountModal';

type UpgradeAccountModalWithButtonProps = {
  pageTitle: string;
  buttonText: string;
  source: string;
};

export const UpgradeAccountModalWithButton = ({
  pageTitle,
  buttonText,
  source
}: UpgradeAccountModalWithButtonProps) => {
  const { redirect } = useRedirect();
  const { isModalOpen, openModal, closeModal } = useModal();
  const trackUpgradeAccountButtonClicked = useTrackUpgradeAccountButtonClicked({
    source
  });
  const homePath = useGetHomeLink();

  const onUpgradeClicked = () => {
    openModal();
    trackUpgradeAccountButtonClicked();
  };

  return (
    <>
      <Button onPress={onUpgradeClicked}>{buttonText}</Button>
      <UpgradeAccountModal
        onUpgradeRequestConfirmed={redirect(homePath)}
        modalOptions={{
          isModalOpen,
          openModal,
          closeModal
        }}
        trackingProperties={{
          pageSource: pageTitle,
          componentSource: source
        }}
      />
    </>
  );
};
