import React from 'react';
import { UpgradeAccountBanner } from '@common/components/AccountUpgrade';
import { useFreeAndUpgradableAccount } from '@common/components/Caja/hooks/useFreeAndUpgradableAccount';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import {
  BackToJobsLink,
  WhatHappensNextInfo
} from '@common/componentsV2/Caja/components';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { useTranslation } from '@common/hooks/useTranslation';
import { useCurrentUser } from '@common/hooks/user';
import { HeaderInfo } from './HeaderInfo';

export const EditJobSuccess = () => {
  useRedirectUnauthenticatedUser();
  const { t } = useTranslation();
  const { email } = useCurrentUser();
  const freeAndUpgradeableAccount = useFreeAndUpgradableAccount();
  const accountUpgradeSource = 'edit_job_success_caja_step';

  return (
    <div className="flex flex-col gap-md mb-md">
      <JoraEmployerHelmet title={t('editJobSuccessPage.title')} />
      <HeaderInfo />
      <WhatHappensNextInfo
        emailAddress={email!}
        showUpgradeAccountInfo={freeAndUpgradeableAccount}
        stepTitle={t('editJobSuccessPage.title')}
        source={`${accountUpgradeSource}_what_happens_next_info`}
      />
      {freeAndUpgradeableAccount && (
        <UpgradeAccountBanner
          pageTitle={t('editJobSuccessPage.title')}
          source={`${accountUpgradeSource}_banner`}
        />
      )}
      <BackToJobsLink />
    </div>
  );
};
