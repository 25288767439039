import React from 'react';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { SingleSelect } from '@common/components/FormElements/SingleSelect';
import { useTranslation } from '@common/hooks/useTranslation';
import { Field } from '@components/FormElements';
import { options } from './options';

type Props = {
  value?: boolean;
  Illustration: React.ReactNode;
  onChange: (key: boolean) => void;
  error?: string;
  disabled?: boolean;
  label?: string | React.ReactNode;
};

export const ImmediateStartField = ({
  value,
  Illustration,
  onChange,
  error,
  label,
  disabled = false
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className="flex gap-4 sm:flex-col-reverse md:flex-row">
      <div className="sm:w-full md:w-3/5 lg:w-3/5">
        <Field
          label={label || t('postJobForm.fieldLabel.immediateStart')}
          message={
            error && (
              <div className="mt-4">
                <ErrorMessage>{error}</ErrorMessage>
              </div>
            )
          }
        >
          <div className="mt-3">
            <SingleSelect<boolean>
              disabled={disabled}
              testID="job-form-immediateStart"
              onChange={onChange}
              options={options(t)}
              value={value}
            />
          </div>
        </Field>
      </div>
      <div className="md:w-2/5 lg:w-2/5">{Illustration}</div>
    </div>
  );
};
