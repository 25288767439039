import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CajaWrapper, StepLoader } from '@common/components/Caja/components';
import {
  useAdTypeState,
  useBrowserBackNavigationListener,
  useCajaVariant,
  useGetPaymentIntentParam,
  useJobAndBusinessLimits
} from '@common/components/Caja/hooks';
import { useTrackCajaEvent } from '@common/components/Caja/hooks/useTrackCajaEvent';
import { useGetCompletedUrl } from '@common/components/Caja/steps/PaymentJobStep/hooks/useGetCompletedUrl';
import { UpdateYourBusinessesModal } from '@common/components/UpdateYourBusinessesModal';
import {
  JobSummaryAndDesc,
  MultiStepJobEdit,
  MultiStepJobRepost,
  PostJobSuccess,
  enhanceJobRouter,
  getImmediateStartPhrase,
  getRightToWorkDocument,
  isEditJobStep,
  isRepostJobStep,
  paymentJobRouter
} from '@common/componentsV2/Caja/steps';
import { useBusinessState } from '@common/hooks/business';
import { useFetchJobData } from '@common/hooks/job';
import { useSteps } from '@common/hooks/multiStep';
import { useUpgradePermissions } from '@common/hooks/payment';
import { useUpdateSourceQuery } from '@common/hooks/useUpdateSourceQuery';
import { SupportedCountryCode as CountryCode } from '@seek/je-shared-data/lib/types/brand';
import { upgraded } from '@src/job';
import { JobLimitReached } from '@src/joraEmployer/pages/job/job-limit-reached';
import { toLowerCase } from '@src/lib/formatting';
import { useGetJobIdParam } from './hooks';
import {
  GetStepsParams,
  RepostJobStepIds,
  getPageTitle,
  getStepNumber,
  getStepTitle,
  getSteps,
  ignoreIndicator,
  totalNumberOfSteps
} from './stepsInfo';

export const RepostJob = () => {
  useUpdateSourceQuery();

  const { originalJobId } = useParams<{ originalJobId: string }>();
  const { hasPaymentIntent } = useGetPaymentIntentParam(); // for handling payment race condition; see https://jobseeker.atlassian.net/browse/AD-753?focusedCommentId=57731
  const urlJobId = useGetJobIdParam();
  const { getOnCompletedUrl } = useGetCompletedUrl();
  const {
    showJobLimitReached,
    refetchJobLimit,
    showOverBusinessLimitBlockingModal
  } = useJobAndBusinessLimits();
  const cajaVariant = useCajaVariant();
  const { isUpgradeable } = useUpgradePermissions();
  const [selectedAdType, setSelectedAdType] = useAdTypeState();

  const {
    data: jobData,
    isLoading: fetchJobLoading,
    refetch
  } = useFetchJobData({
    jobId: urlJobId || originalJobId
  });

  const [createdJobCountryCode, setCountryCode] = useBusinessState(
    toLowerCase<CountryCode>(jobData?.countryCode)
  );
  const isJobUpgradable = isUpgradeable(createdJobCountryCode);

  const { steps, currentStep, gotoNextStep, nextStep, gotoPreviousStep } =
    useSteps<GetStepsParams, RepostJobStepIds>({
      data: {
        createdJobId: urlJobId,
        isUpgradeable: isJobUpgradable,
        hasJobUpgraded: upgraded(jobData?.adType) || hasPaymentIntent,
        selectedAdType
      },
      getSteps
    });

  useEffect(() => {
    if (urlJobId) refetch();
  }, [currentStep.id, urlJobId, refetch]);

  // Refetch the Job Limit if we're on a repost job step to ensure we show the JobLimitReached component if the limit has been reached
  useEffect(() => {
    if (isRepostJobStep(currentStep.id)) refetchJobLimit();
  }, [refetchJobLimit, currentStep.id]);

  useBrowserBackNavigationListener(gotoPreviousStep);

  useTrackCajaEvent({
    step: currentStep.id,
    variant: `repost-job-${cajaVariant}`,
    jobId: urlJobId,
    repostJobId: originalJobId
  });

  const EnhanceJob = enhanceJobRouter({ cajaVariant });
  const PaymentJob = paymentJobRouter({ cajaVariant });

  if (fetchJobLoading) return <StepLoader />;

  if (showJobLimitReached && isRepostJobStep(currentStep.id)) {
    return <JobLimitReached pageTitle={getPageTitle(currentStep.id)} />;
  }

  return (
    <CajaWrapper
      title={getPageTitle(currentStep.id)}
      ignoreIndicator={ignoreIndicator(currentStep.id)}
      step={{
        number: getStepNumber(steps, currentStep.path),
        title: getStepTitle(currentStep.id),
        total: totalNumberOfSteps(steps)
      }}
    >
      {showOverBusinessLimitBlockingModal && <UpdateYourBusinessesModal />}
      {isRepostJobStep(currentStep.id) && (
        <MultiStepJobRepost
          onCompleted={({ jobId: id, jobCountryCode }) => {
            setCountryCode(jobCountryCode);
            gotoNextStep({
              createdJobId: id,
              isUpgradeable: isUpgradeable(
                toLowerCase<CountryCode>(jobCountryCode)
              )
            });
          }}
          onNext={(data) => {
            if (data.jobCountryCode) setCountryCode(data.jobCountryCode);
            gotoNextStep(data);
          }}
          onBack={gotoPreviousStep}
          stepInfo={{
            repostJobId: originalJobId,
            currentStepId: currentStep.id
          }}
        />
      )}
      {isEditJobStep(currentStep.id) && (
        <MultiStepJobEdit
          onCompleted={gotoNextStep}
          onNext={gotoNextStep}
          onBack={gotoPreviousStep}
          stepInfo={{
            jobId: urlJobId!,
            job: jobData!!,
            currentStepId: currentStep.id
          }}
        />
      )}
      {currentStep.id === 'enhanceJob' && (
        <EnhanceJob
          stepInfo={{
            jobId: urlJobId,
            jobCountryCode: createdJobCountryCode!,
            disableAdTypeSelector: upgraded(jobData?.adType),
            initialAdType: upgraded(jobData?.adType)
              ? jobData?.adType
              : selectedAdType
          }}
          onCompleted={({ jobAdType, hasJobUpgraded }) => {
            setSelectedAdType(jobAdType);
            gotoNextStep({
              createdJobId: urlJobId,
              selectedAdType: jobAdType,
              hasJobUpgraded: hasJobUpgraded || upgraded(jobData?.adType)
            });
          }}
          onBack={gotoPreviousStep}
        />
      )}
      {currentStep.id === 'payment' && upgraded(selectedAdType) && (
        <PaymentJob
          stepInfo={{
            jobId: urlJobId,
            jobCountryCode: createdJobCountryCode!,
            adType: selectedAdType,
            onCompletedUrl: getOnCompletedUrl(nextStep)
          }}
          onBack={gotoPreviousStep}
        />
      )}
      {currentStep.id === 'jobSummaryAndDesc' && (
        <JobSummaryAndDesc
          stepInfo={{
            jobId: urlJobId,
            showAdTypeBadge: isJobUpgradable,
            adType: selectedAdType,
            rightToWorkDocument: getRightToWorkDocument(cajaVariant),
            immediateStartPhrase: getImmediateStartPhrase(createdJobCountryCode)
          }}
          onCompleted={gotoNextStep}
          onBack={gotoPreviousStep}
        />
      )}
      {currentStep.id === 'postJobSuccess' && (
        <PostJobSuccess
          stepInfo={{
            cajaVariant,
            jobUpgradeInfo: {
              isJobUpgradable,
              jobCountryCode: createdJobCountryCode!,
              jobId: urlJobId!
            }
          }}
        />
      )}
    </CajaWrapper>
  );
};
