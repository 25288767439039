import React from 'react';
import { RadioCard } from '@common/components/FormElements/RadioCard';
import { AD_TYPE } from '@seek/je-shared-data';
import { MaxAdInfo } from '../AdInfo';

type MaxAdCardProps = {
  immediateStartText: string;
  adDuration?: number;
  price: React.ReactNode;
  onChange: (adType: AD_TYPE) => void;
  value: string;
  disabled: boolean;
};

export const MaxAdCard = (props: MaxAdCardProps) => {
  const { adDuration, onChange, value, disabled, price, immediateStartText } =
    props;

  const isValueMaxAd = () => value === AD_TYPE.MAX;

  return (
    <RadioCard
      testID="max-radio-card"
      checked={isValueMaxAd()}
      onPress={() => onChange(AD_TYPE.MAX)}
      disabled={disabled}
      content={
        <div className="mt-sm px-1">
          <MaxAdInfo
            adDuration={adDuration}
            price={price}
            immediateStartText={immediateStartText}
          />
        </div>
      }
    />
  );
};
