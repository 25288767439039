import React from 'react';
import { RequestErrorMessage } from '@common/components/RequestErrorMessage';
import { PremiumUpsell } from '@common/components/upgradeJobAd';
import { CurrencyAmount } from '@common/components/upgradeJobAd/PriceInformation';
import { useGetAdDuration } from '@common/hooks/job';
import { useLoadAdPrices } from '@common/hooks/payment';
import { useTranslation } from '@common/hooks/useTranslation';
import { AD_TYPE } from '@seek/je-shared-data';
import { Heading } from '@components/Heading';
import { Props } from '../types';

export const UpgradeJobContent = ({
  jobCountryCode,
  jobId,
  campaignId
}: Props) => {
  const { t } = useTranslation();
  const { data: adPrice, errorKey } = useLoadAdPrices(jobCountryCode);
  const { adDuration } = useGetAdDuration(jobCountryCode);

  return (
    <div className="flex flex-col pt-[24px] p-lg gap-md bg-background-neutral">
      <div className="pb-[12px]">
        <Heading level="h2" weight="regular">
          {t('enhanceJobStep.contentHeading')}
        </Heading>
      </div>
      <PremiumUpsell
        adDuration={adDuration}
        countryCode={jobCountryCode}
        jobId={jobId}
        campaignId={campaignId}
        price={{
          [AD_TYPE.PLUS]: <CurrencyAmount price={adPrice[AD_TYPE.PLUS]} />,
          [AD_TYPE.MAX]: <CurrencyAmount price={adPrice[AD_TYPE.MAX]} />
        }}
      />

      <RequestErrorMessage requestKey={errorKey} />
    </div>
  );
};
