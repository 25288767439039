import React from 'react';
import { UpgradeAccountBanner } from '@common/components/AccountUpgrade';
import { useFreeAndUpgradableAccount } from '@common/components/Caja/hooks';
import { Box } from '@components/Box';

type UpgradeBannerProps = {
  pageTitle: string;
};

export const UpgradeBanner = ({ pageTitle }: UpgradeBannerProps) => {
  const freeAndUpgradeableAccount = useFreeAndUpgradableAccount();

  if (!freeAndUpgradeableAccount) return null;

  return (
    <Box
      marginBottom="medium"
      marginHorizontal={{
        desktop: 'none',
        tablet: 'none',
        mobile: 'medium'
      }}
    >
      <UpgradeAccountBanner
        pageTitle={pageTitle}
        source="dashboard_page_banner"
      />
    </Box>
  );
};
