import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@components/Modal';
import { RequestUpgrade, RequestUpgradeConfirmation } from './components';

type UpgradeModalProps = {
  mobile?: string;
  email?: string;
  visible: boolean;
  onRequestClose: () => void;
  onUpgradeRequested: () => void;
  onUpgradeRequestConfirmed: () => void;
  upgradeRequestConfirmedText?: string;
};

export const BaseUpgradeAccountModal = ({
  mobile,
  email,
  visible,
  onRequestClose,
  onUpgradeRequested,
  onUpgradeRequestConfirmed,
  upgradeRequestConfirmedText
}: UpgradeModalProps) => {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState<boolean>(false);

  const handleUpgradeRequested = () => {
    onUpgradeRequested();
    setSubmitted(true);
  };

  const handleUpgradeConfirmed = () => {
    onUpgradeRequestConfirmed();
  };

  return (
    <Modal
      title={t('account.upgradeAccountModal.title')}
      visible={visible}
      onRequestClose={onRequestClose}
      height="fit-content"
    >
      {submitted ? (
        <RequestUpgradeConfirmation
          mobile={mobile}
          email={email}
          onComplete={handleUpgradeConfirmed}
          onCompleteText={upgradeRequestConfirmedText}
        />
      ) : (
        <RequestUpgrade
          mobile={mobile}
          email={email}
          onSubmit={handleUpgradeRequested}
        />
      )}
    </Modal>
  );
};
